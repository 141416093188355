@use 'sass:map';
@use 'sass:math';
@use 'sass:string';

@function str-replace($string, $search, $replace: '') {
    $index: string.index($string, $search);

    @if $index {
      @return string.slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + string.length($search)), $search, $replace);
    }

    @return $string;
  }

@function to-rem($value, $root: $font-size-root-value) {
    $rem-value: $value;

    @if math.unit($value) == 'px' {
        $rem-value: math.div(math.div($value, 1px), $root) * 1rem;
    } @else if math.is-unitless($value) {
        $rem-value: math.div($value, $root) * 1rem;
    }

    @return $rem-value;
}

@function to-px($value, $root: $font-size-root-value) {
    $px-value: $value;

    @if math.unit($value) == 'rem' {
        $px-value: math.div($value, 1rem) * $root * 1px;
    } @else if math.is-unitless($value) {
        $px-value: $value * $root * 1px;
    }

    @return $px-value;
}

////////////////////////////////////////////////////////
// Bootstrap Variable Overrides
////////////////////////////////////////////////////////
// rem reset to 10px per 1rem
$font-size-root-value: 10;
$rfs-rem-value: $font-size-root-value;
$font-size-root: $font-size-root-value * 1px;

// Grid columns
$grid-gutter-width: 1.6rem;
$spacer: $grid-gutter-width * 0.5;
$spacers: (
    0: 0,
    1: $spacer * 1,
    2: $spacer * 2,
    3: $spacer * 3,
    4: $spacer * 4,
    5: $spacer * 5,
    6: $spacer * 6,
    7: $spacer * 7,
    8: $spacer * 8,
    9: $spacer * 9,
    10: $spacer * 10,
);

// Border radius
$border-radius: 8px;
$border-radius-xl: $border-radius * 2.75;
$border-radius-lg: $border-radius * 1.5;
$border-radius-sm: $border-radius * 0.5;

// Grid breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: map.get($breakpoints, 'small'),
    md: map.get($breakpoints, 'medium'),
    lg: map.get($breakpoints, 'large'),
    xl: map.get($breakpoints, 'xlarge'),
    xxl: map.get($breakpoints, 'xxlarge'),
    xxxl: map.get($breakpoints, 'xxxlarge'),
    xxxxl: map.get($breakpoints, 'xxxxlarge'),
);
$container-max-widths: (
    md: map.get($grid-breakpoints, 'md') - (to-px($grid-gutter-width) * 6),
    lg: map.get($grid-breakpoints, 'lg') - (to-px($grid-gutter-width) * 7),
    xl: map.get($grid-breakpoints, 'xl') - (to-px($grid-gutter-width) * 8),
    xxl: map.get($grid-breakpoints, 'xxl') - (to-px($grid-gutter-width) * 10),
    xxxl: map.get($grid-breakpoints, 'xxxl') - (to-px($grid-gutter-width) * 12),
    xxxxl: map.get($grid-breakpoints, 'xxxxl') - (to-px($grid-gutter-width) * 15),
);

// theme colors
$primary: $black;
$light: $white;
$dark: $black;

// Utility colors
$success: $green-utility;
$info: $blue-utility;
$warning: $yellow-utility;
$danger: $red-utility;

// reboot
$link-color: $black;

// inputs
$input-bg: $white;
$input-border-radius: 3rem;
$input-border-color: rgba($black, 0.06);
$input-border-width: 2px;
$input-focus-box-shadow: none;
$input-focus-border-color: rgba($black, 0.06);
$input-focus-width: 2px;
$input-focus-color: $black;
$input-color: $black;
$input-line-height: 1.5;
$input-padding-y: 2rem;
$input-padding-x: 3.2rem;
$input-placeholder-color: $grey-tundora;
$form-check-input-focus-box-shadow: 0 0 0 0.2rem $blue-utility;
$form-check-margin-bottom: $spacer;
$form-check-input-width: 2rem;
$form-check-input-width-mbl: 1.8rem;
$form-check-padding-start: $form-check-input-width + .6rem;
$form-check-input-border: 2px solid $black;

// selects
$form-select-padding-x: .4rem;
$form-select-indicator-padding: 2.5rem;
$form-select-indicator: var(--chevron-down-small);
$form-select-indicator-dark-theme: var(--chevron-down-small-white);
$form-select-bg-size: 12px 8px; // In pixels because image dimensions
$form-select-bg-position: right $form-select-padding-x center;
$form-select-feedback-icon-padding-end: $form-select-padding-x * 3.5 + $form-select-indicator-padding;
$form-select-feedback-icon-position: center right $form-select-indicator-padding;
$form-select-border-color: rgba($black, 0.06);
$form-select-border-radius: 3rem;
$form-select-border-width: 2px;
$form-select-color: $grey-tundora;
$form-select-focus-border-color: $black;
$form-select-focus-box-shadow: $black;
$form-select-padding-y: 2rem;
$form-select-padding-x: 3.2rem;
$form-select-height: auto;

// form validation
$enable-validation-icons: true;
$form-feedback-invalid-color: $danger;
$form-feedback-font-size: 1.2rem;
$form-feedback-icon-valid: var(--form-feedback-icon-valid);
$form-feedback-icon-invalid: none;
$form-feedback-tooltip-font-size: $form-feedback-font-size;

// theme
$light: $white;
$dark: $black;

// buttons
$btn-padding-y-xs: 0.2rem;
$btn-padding-x-xs: 0.7rem;
$btn-padding-y-sm: 0.5rem;
$btn-padding-x-sm: 2rem;
$btn-padding-y: 0.9rem;
$btn-padding-x: 2.8rem;
$btn-padding-y-lg: 1.5rem;
$btn-padding-x-lg: 2.8rem;
$btn-border-width: 0.4rem;
$btn-disabled-opacity: .5;
$btn-box-shadow: none;
$btn-focus-width: 0;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$btn-font-size-xs: 1rem;
$btn-font-size-sm: 1.2rem;
$btn-font-size: 1.6rem;
$btn-font-size-lg: 1.6rem;
$btn-font-size-xl: 2rem;
$btn-font-weight: 500;
$btn-font-family: var(--bs-body-font-family);
$btn-line-height: 1.2;

// $btn-link-disabled-color: $gray-600;

// // Allows for customizing button radius independently from global border radius
$btn-border-radius: 3rem;
$btn-border-radius-lg: 3rem;
$btn-border-radius-sm: 3rem;

// badges
$badge-border-radius: $border-radius-sm;
$badge-padding: 0.55rem 0.8rem;
$badge-line-height: 1.05;
$badge-font-weight: 500;

// $btn-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$nav-pills-border-radius: 3rem;
$nav-pills-link-active-color: $black;

// breadcrumbs
$breadcrumb-divider: '';
$breadcrumb-divider-color: $black;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: 0.3rem;
$breadcrumb-item-padding-x: 0.3rem;

// typography
$font-family-sans-serif: $font-medium;
$font-family-base: $font-family-sans-serif;
$line-height-base: 1.5;
$font-weight-light: 100;
$font-weight-thin: $font-weight-light;
$font-weight-normal: 300;
$font-weight-regular: $font-weight-normal;
$font-weight-medium: 500 ;
$font-weight-bold: 700;
$font-weight-heavy: 850;

// Options
$enable-negative-margins: true;

// Accordions
$accordion-bg: transparent;
$accordion-button-color: $black;
$accordion-button-active-bg: transparent;
$accordion-border-width: 0;
$accordion-icon-width: 1.7rem;
$accordion-border-width: 2px;
$accordion-border-color: $black;
$accordion-border-radius: 0;
$accordion-button-icon: var(--chevron-down-small);
$accordion-button-active-icon: var(--chevron-down-small);

// Alerts
$alert-padding-y: $spacer;
$alert-padding-x: $spacer * 2;
$alert-margin-bottom: $spacer * 3;
$alert-border-width: 0;
$alert-color-scale: 100%;

// Close Button
$btn-close-bg: svg('cartridges/app_patagonia_base/cartridge/assets/svgs/x.svg');

// Body
$body-bg: $white;
$body-color: $black;

// card
$card-cap-bg: transparent;

// Tooltips
$tooltip-font-size: 1.2rem;
$tooltip-max-width: 23.2rem;
$tooltip-color: $white;
$tooltip-bg: $black;
$tooltip-border-radius: $border-radius;
$tooltip-opacity: 1;
$tooltip-padding-y: calc(var(--bs-gutter-x) * 0.5);
$tooltip-padding-x: var(--bs-gutter-x);
$tooltip-margin: 0;
$tooltip-arrow-width: 1.6rem;
$tooltip-arrow-height: 1.6rem;
$tooltip-arrow-color: $tooltip-bg;

// Popover
$popover-font-size: 1.2rem;
$popover-max-width: 23.2rem;
$popover-color: $white;
$popover-bg: $black;
$popover-border-radius: $border-radius;
$popover-header-padding-y: calc(var(--bs-gutter-x) * 0.5);
$popover-header-padding-x: var(--bs-gutter-x);
$popover-arrow-width: 1.6rem;
$popover-arrow-height: 1.6rem;
$popover-arrow-color: $black;

// Headings
$headings-font-weight: 500;

// CSS Grid
$enable-cssgrid: true;
